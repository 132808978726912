<template>
  <b-card>
    <b-overlay :show="show" size="lg" spinner-variant="primary">
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="2">
              <v-select
                  v-model="_filter.store_id"
                  label="store_name"
                  input-id="store"
                  :options="getStores"
                  :reduce="store => store.id"
                  class="select-size-sm"
                  placeholder="Pazaryeri"
                  :closeOnSelect="true"
              />

              <b-form-radio name="in_store" v-model="_filter.in_store" value=""> Satışta Tümü</b-form-radio>
              <b-form-radio name="in_store" v-model="_filter.in_store" value="on_sale"> Var</b-form-radio>
              <b-form-radio name="in_store" v-model="_filter.in_store" value="not_on_sale"> Yok</b-form-radio>
            </b-col>
            <b-col cols="2">
              <v-select
                  v-model="_filter.brandIds"
                  :options="_brands"
                  label="name"
                  :reduce="item => item.id"
                  class="select-size-sm"
                  input-id="brand"
                  multiple
                  placeholder="Markalar"
                  :closeOnSelect="false"
              />
              <b-form-radio name="in_archived" v-model="_filter.in_archived" value=""> Arşiv Tümü</b-form-radio>
              <b-form-radio name="in_archived" v-model="_filter.in_archived" value="1"> Var</b-form-radio>
              <b-form-radio name="in_archived" v-model="_filter.in_archived" value="0"> Yok</b-form-radio>
            </b-col>
            <b-col cols="2">
              <b-form-input type="text" size="sm" v-model="_filter.merchant_sku" placeholder="PY Barkod"/>
            </b-col>
            <b-col cols="2">
              <b-form-input type="text" size="sm" v-model="_filter.product.id" placeholder="Ürün No"/>
              <b-form-input type="text" size="sm" v-model="_filter.product.barcode" placeholder="Ürün Barkod"/>
              <b-form-input type="text" size="sm" v-model="_filter.product.name" placeholder="Ürün Adı"/>
            </b-col>
            <b-col cols="2">
              <v-select
                  v-model="_filter.limit"
                  :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
                  class="select-size-sm"
                  placeholer="Limit"
              />
              <v-select
                  v-model="_filter.sorting"
                  label="key"
                  :options="sortingList"
                  :reduce="item => item.val"
                  class="select-size-sm"
                  placeholder="Sıralama"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <b-button-group>
            <b-button
                :disabled="!_filter.store_id"
                variant="primary"
                size="sm"
                @click="getFilter(1)"
            >
              <feather-icon icon="FilterIcon"></feather-icon>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1">
          <b-form-checkbox
              v-model="isAllSelected"
              :disabled="_products.length<1"
              class=" custom-control-secondary"
              @change="selectAllProduct"
          >
          {{ _selectedIds.length }} / {{ _products.length }}
          </b-form-checkbox>
        </b-col>

        <b-col cols="1" class="m-0 p-0">
          <v-select :options="['Seçilenler','Tamamı']" class="select-size-sm"/>
        </b-col>

        <b-col cols="1" class="m-0 p-0">
          <v-select :options="['Ekle','Arşive Al','Sil']" class="select-size-sm"/>
        </b-col>

        <b-col cols="1" class="m-0 p-0">
          <b-button-group>
            <b-button variant="primary" size="sm">Uygula</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <Pagination :pagination="_pagination" @pageClick="getFilter"/>
    </b-overlay>

  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "HomeFilter",
  components: {vSelect, Pagination},
  data: () => ({
    show: false,
    sortingList: [
      {key: 'A-Z', val: 'ASC'},
      {key: 'Z-A', val: 'DESC'},
    ],
  }),
  computed: {
    ...mapGetters('tyCreateProduct', ['_products', '_filter', '_selectedIds', '_pagination']),
    ...mapGetters('productBrand', ['_brands']),
    ...mapGetters('store', ['_stores']),
    getStores() {
      return this._stores.filter(store => store.marketplace_code === 'TY')
    },
    isAllSelected: {
      get() {
        return this._products.length > 0 && this._selectedIds.length >= this._products.length;
      },
      set() {

      }
    }
  },
  methods: {
    getFilter(page) {
      this.show = true;
      this._filter.page = page;
      this.$store.dispatch('tyCreateProduct/getFilter')
          .then(res => {
            this.show = false;
          })
    },
    selectAllProduct(event) {
      console.log(event)
      let selected = [];
      if (event) selected = this._products.map(product => product.id)
      else selected = [];
      this.$store.state.tyCreateProduct.selectedIds = selected
    }
  }
}
</script>

<style scoped>

</style>
