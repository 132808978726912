<template>
  <b-card>
    <b-row>
      <b-col cols="1">
        <b-form-checkbox
            v-model="$store.state.tyCreateProduct.selectedIds"
            :value="tyProduct.id"> {{ tyProduct.id }}
        </b-form-checkbox>
      </b-col>

      <b-col cols="1">
        <product-image :images="tyProduct.images" url=""/>
      </b-col>
      <b-col cols="5">
        <h4>{{ tyProduct.product_name }}</h4>
        <div>
          <b>{{ tyProduct.merchant_sku }}</b>({{ tyProduct.id }})
        </div>

        <hr>
        <div v-if="brands">
          <b-row v-for="brand in brands" :key="brand.id">
            <b-col cols="4">
              <b-badge> {{ brand.name }}</b-badge>
            </b-col>
            <b-col>
              <b-overlay :show="isAddStoreBrand">
                <b-button-group>
                  <b-button size="sm" @click="addStoreBrand('add',_filter.store_id,brand.id)">Marka Ekle</b-button>
                  <b-button size="sm" @click="addStoreBrand('del',_filter.store_id,brand.id)">Marka Çıkart</b-button>
                </b-button-group>
              </b-overlay>
            </b-col>

            <b-col>
              <b-row>
                <b-col>BELGE BEKLEYENLER</b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox plain> Tümü</b-form-checkbox>
                </b-col>
                <b-col v-for="store in getStores" :key="store.id">
                  <b-form-checkbox plain @change="selectStore($event,store.id)"> {{
                      store.store_name
                    }}
                  </b-form-checkbox>
                </b-col>
              </b-row>

            </b-col>


          </b-row>

        </div>
        <hr>
        <ty-product-product
            v-for="product in tyProduct.products"
            :product="product"
            :ty-product="tyProduct"
            :key="product.id"/>
      </b-col>
      <b-col cols="5">
        <b-overlay :show="isLoading">
          <b-row v-for="(storeProduct,index) in tyProduct.ty_store_products" :key="storeProduct.id">
            <b-col cols="2">{{ storeProduct.store.store_name }}</b-col>
            <b-col>
              <b-button-group size="sm">
                <b-button
                    class="pr-2"
                    v-if="storeProduct.archived"
                    variant="warning"
                    @click="archived(storeProduct.id,0)"

                >Aktif Et
                </b-button>
                <b-button
                    v-else
                    variant="info"
                    @click="archived(storeProduct.id,1)"
                >Arşive Al
                </b-button>
                <b-button variant="danger" @click="delStoreProduct(storeProduct.id,index)">Sil</b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <hr>
          <b-row v-for="store in getStores" :key="store.id">
            <b-col cols="2">{{ store.store_name }}</b-col>
            <b-col>
              <b-button variant="primary" size="sm" @click="addStoreProduct(store.id)">Ekle</b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>


<script>
import ProductImage from "@/components/ProductImage.vue";
import TyProductProduct from "@/views/trendyol/create-product/TyProductProduct.vue";
import {mapGetters} from "vuex";
import axios from "@/libs/axios";

export default {
  name: "TyProductList",
  components: {ProductImage, TyProductProduct},
  data: () => ({
    isLoading: false,
    isAddStoreBrand: false,
  }),
  props: {
    tyProduct: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters('tyCreateProduct', ['_filter']),
    ...mapGetters('store', ['_stores']),
    status() {
      return this.tyProduct.ty_store_products.length > 0 ? true : false;
    },
    getStores() {
      return this._stores.filter(store => store.marketplace_code === 'TY')
    },
    brands() {
      let brands = [];
      let brandIds = [];
      this.tyProduct.products.forEach(product => {
        if (product.brand && !brandIds.includes(product.brand.id)) {
          brands.push(product.brand)
          brandIds.push(product.brand.id)
        }
      })

      return brands;
    }
  },
  methods: {
    addStoreBrand(status, storeId, brandId) {
      this.isAddStoreBrand = true;
      this.isAddStoreBrandMsg = '';
      if (status === 'del') {
        axios.delete(`/store/${storeId}/brand/${brandId}`)
            .then(res => {
              this.isAddStoreBrand = false;
              this.$swal({
                title: res.data.message,
                timer: 1000,
              })
            })
      } else if (status === 'add') {
        axios.post(`/store/${storeId}/brand/${brandId}`)
            .then(res => {
              this.isAddStoreBrand = false;
              this.$swal({
                title: res.data.message,
                timer: 1000,
              })
            })
      }
    },
    archived(storeProductId, archived) {
      console.log(storeProductId, archived)
      axios.put(`/trendyol/create-product/${storeProductId}/archived/${archived}`)
          .then(res => {

          })
    },
    delStoreProduct(storeProductId, index) {
      this.isLoading = true;
      axios.delete(`/trendyol/create-product/${storeProductId}`)
          .then(res => {
            if (res.data.status === 'success') this.tyProduct.ty_store_products.splice(index, 1);
            this.isLoading = false;
          })
    },
    addStoreProduct(storeId) {
      this.isLoading = true;
      axios.post('/trendyol/create-product', {
        store_id: storeId,
        ty_product_id: this.tyProduct.id,
      })
          .then(res => {
            if (res.data.status === 'success') {
              this.tyProduct.ty_store_products.push(res.data.content)
            }
            this.isLoading = false;
          })
    },
    selectStore(event, storeId) {
      alert('Yapılacak');
    }
  }
}
</script>

<style scoped>

</style>
