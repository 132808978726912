<template>
  <div>
    <home-filter/>
    <!-- <product-list v-for="product in _products" :product="product" :key="product.id"/> -->
    <ty-product-list v-for="product in _products" :ty-product="product" :key="product.id"/>
  </div>
</template>

<script>
import HomeFilter from "@/views/trendyol/create-product/HomeFilter.vue";
import TyProductList from "@/views/trendyol/create-product/TyProductList.vue";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  components: {HomeFilter, TyProductList},
  computed: {
    ...mapGetters('tyCreateProduct', ['_products'])
  },
  mounted() {
    this.$store.dispatch('productBrand/getAll')
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
