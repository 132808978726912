<template>
  <div>
    <b-button size="sm" :id="'images-'+tyProduct.id+'-'+product.id" variant="default">
      <b>{{ product.name }}</b>
    </b-button>

    <b-popover
        :target="'images-'+tyProduct.id+'-'+product.id"
        triggers="hover" placement="top"
    >
      <product-image :images="product.images"/>
    </b-popover>
    <div>
      <b>{{ product.barcode }}</b> ({{ product.id }})
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/ProductImage.vue";

export default {
  name: "TyProductProduct",
  components: {ProductImage},
  props: {
    tyProduct: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
